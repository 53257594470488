// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import Constants from "expo-constants";
import type { Configuration as EnvConfig } from "../configuration";
import type { GetConfiguration200Response } from "../gen/org-portal";

const envConfig = Constants.manifest?.extra as EnvConfig;

export type ConfigurationState = {
  readonly configuration?: GetConfiguration200Response;
  readonly environmentName?: string;
  readonly updateConfiguration: () => Promise<void>;
  readonly clear: () => void;
};

// Defaults are the values used in production
const defaultConfiguration: GetConfiguration200Response = {
  accountFolioTemplateUUID: "36a825fa-35a5-4aad-a14f-a9e888ef1be6",
  accountMeritTemplateUUID: "b3949acb-1457-40fd-9c2e-e17d2bf4a43e",
  adminMeritTemplateUUID: "ab2f6248-7b7e-4560-997d-90f97c6af1c3",
  adminRegistrationDatasourceUUID: "", // appears to not be used
  baseFolioTemplateUUID: "461446de-86b2-438c-b5a3-edfa5c540484",
  baseMeritTemplateUUID: "c378f97c-c09a-41b2-b206-f598d5413f14",
  basePersonaTemplateUUID: "913589d5-e617-493e-8f53-1be6964a607d",
  basePolicyUUID: "a7ac757e-2386-49dc-bd32-aedf4f3ee609",
  launchDarklyClientID: "64e7d5654f661f13032761b7",
  meritCheckinURL: "https://checkin.merits.com",
  orgAdminDatasourceUUID: "440e7584-0ec4-41e9-a485-465156d74baa",
  orgRegistrationDatasourceUUID: "2bdabf49-92f7-4ae2-b66b-abb8f75859b6",
  solName: "Merit International, Inc.",
  solUUID: "900d3375-54c2-48ca-8f85-e0d9d991138a",
};

/*
Do not use this outside of where the configuration store is loaded and initialized!
Use the useLoadedConfigurationState hook instead.
 */
export const useConfigurationStore = create<ConfigurationState>()(
  persist(
    set => ({
      clear: () => {
        set(() => ({ configuration: undefined }));
      },
      configuration: undefined,
      environmentName: undefined,
      updateConfiguration: async () => {
        try {
          const res = await fetch(`${envConfig.api.orgPortal.baseUrl}/configuration`);
          const newConfiguration = await res.json();

          set(() => ({
            configuration: newConfiguration,
            environmentName: envConfig.environment,
          }));
        } catch {
          // eslint-disable-next-line no-console
          console.error("failed to fetch configuration - setting production defaults");

          set(() => ({
            configuration: defaultConfiguration,
            environmentName: envConfig.environment,
          }));
        }
      },
    }),
    {
      name: "orgportal-configuration-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
