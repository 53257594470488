import { Body, Button } from "@merit/frontend-components";
import { Datagrid, DatagridBody, EllipsisText } from "../../../components";
import { Helpers } from "@merit/frontend-utils";
import { ScrollView, View } from "react-native";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import React from "react";
import type { DatagridColumn } from "../../../components/Datagrid/types";
import type { GetAllAgentsVersions200ResponseInner } from "../../../gen/org-portal";

const { None, Some } = Helpers;
const SCREEN_NAME = "LinkedApps";

type Props = {
  readonly linkedApps: readonly GetAllAgentsVersions200ResponseInner[];
  readonly onPressView: (policyID: string) => void;
  readonly isLoading: boolean;
};

export const LinkedAppsTable = ({ linkedApps, onPressView }: Props) => {
  const { configuration } = useLoadedConfigurationState();

  const getOrgName = (orgId?: string, orgName?: string) => {
    if (None(orgName)) {
      return "--";
    }

    if (configuration.solUUID === orgId) {
      return configuration.solName;
    }

    return orgName;
  };

  const columns: readonly DatagridColumn<GetAllAgentsVersions200ResponseInner>[] = [
    {
      key: "name",
      label: "Name",
      renderer: ({ versions }, testProps) => {
        const lastVersion = versions[0];

        return <EllipsisText testProps={testProps} text={lastVersion.name} />;
      },
      size: "flex",
    },
    {
      key: "description",
      label: "Description",
      renderer: ({ versions }, testProps) => {
        const description = versions[0].description;

        return (
          <EllipsisText
            testProps={testProps}
            text={Some(description) && description.length > 0 ? description : "--"}
          />
        );
      },
      size: "flex",
    },
    {
      key: "orgName",
      label: "Owner",
      renderer: ({ orgName, owner }, testProps) => (
        <EllipsisText testProps={testProps} text={getOrgName(owner, orgName)} />
      ),
      size: "flex",
    },
    {
      key: "state",
      label: "State",
      renderer: (item, testProps) => (
        <Body capitalize testProps={testProps}>
          {item.state === "live" ? "Live" : "Pending"}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: (item, testProps) => (
        <Body capitalize testProps={testProps}>
          {item.status}
        </Body>
      ),
      size: "flex",
    },
    {
      key: "actions",
      label: "Actions",
      renderer: ({ id }, testProps) => (
        <View style={{ width: 80 }}>
          <Button
            onPress={() => {
              if (Some(id)) {
                onPressView(id);
              }
            }}
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}ViewButton`,
                  }
                : testProps
            }
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 128,
    },
  ];

  return (
    <ScrollView>
      <Datagrid
        columns={columns}
        testProps={{
          elementName: "linkedAppsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={linkedApps}
          getItemKey={g => g.id}
          testProps={{
            elementName: "linkedAppsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
    </ScrollView>
  );
};
