// Copyright 2023 Merit International Inc. All Rights Reserved

import { Drawer } from "../../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { LinkedAppDetails } from "../LinkedAppDetails/LinkedAppDetails";
import { LinkedAppsTable } from "./LinkedAppsTable";
import { Spin, VerticalSpacer } from "../../../components";
import { View } from "react-native";
import { useApi } from "../../../api/api";
import { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { useLoggedInAuthState } from "../../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../../utils/useServerErrorHandler";
import type { GetAllAgentsVersions200ResponseInner } from "../../../gen/org-portal";

const { Some } = Helpers;

export const LinkedApps = () => {
  const { api } = useApi();

  const { selectedOrgId } = useLoggedInAuthState();
  const { errorHandler } = useServerErrorHandler();

  const [linkedApps, setLinkedApps] = useState<readonly GetAllAgentsVersions200ResponseInner[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAppID, setSelectedAppID] = useState<string>();

  const getLinkedApps = useCallback(async () => {
    try {
      const orgAppsVersionsResponse = await api.getAllAgentsVersions({
        includeApprovedAgents: true,
        includeApprovedVersions: true,
        limit: 100,
        orgID: selectedOrgId,
        ownerID: selectedOrgId,
      });
      const orgAppsVersionsSorted = orgAppsVersionsResponse.map(app => {
        const sortedVersions = app.versions.toSorted((a, b) => (a.version > b.version ? -1 : 1));

        return {
          ...app,
          versions: sortedVersions,
        };
      });
      setLinkedApps(orgAppsVersionsSorted);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [api, errorHandler, selectedOrgId]);

  useFocusEffect(
    useCallback(() => {
      getLinkedApps();
    }, [getLinkedApps])
  );

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Spin />
      </View>
    );
  }

  return (
    <>
      <LinkedAppsTable
        isLoading={isLoading}
        linkedApps={linkedApps}
        onPressView={id => {
          setSelectedAppID(id);
        }}
      />
      <VerticalSpacer size={48} />
      <Drawer isOpen={Some(selectedAppID)}>
        <LinkedAppDetails
          appID={selectedAppID}
          onClose={() => {
            setSelectedAppID(undefined);
          }}
          onStatusChange={() => {
            getLinkedApps();
          }}
        />
      </Drawer>
    </>
  );
};
