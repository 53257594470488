// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import {
  Body,
  Button,
  Heading,
  Icon,
  Select,
  TextInput,
  useTheme,
} from "@merit/frontend-components";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { RulesArgumentInput } from "./RulesArgumentInput";
import { SelectPermissions } from "../../components/SelectPermissions";
import { Tooltip } from "../../components";
import { getDateString, getDateTimeString } from "../../utils/time";
import { useRoute } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import type {
  EditFieldKindOperationRequest as EditFieldKindRequest,
  ExtendFieldKindOperationRequest as ExtendFieldKindRequest,
  GetFieldKind200Response as FieldKind,
  GetTemplateRules200ResponseRules as PredicateMap,
  OrgsGet200ResponseContainersInnerCompletenessFailuresInnerAtomPredicateEnum as PredicatesEnum,
  GetDatasource200ResponseMappedTemplatesInnerOwnCompletenessRuleRuleConditionsInner as RuleCondition,
} from "../../gen/org-portal";
import type { FormikProps } from "formik";
import type { Predicate } from "./RulesArgumentInput";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

type PermissionsValue = "no" | "yes";

export type FormValues = {
  readonly name?: string;
  readonly description?: string;
  readonly dataType?: string;

  readonly permissions: {
    readonly readPermission: PermissionsValue;
    readonly extendPermission: PermissionsValue;
  };

  readonly validationRules?: readonly {
    readonly predicate?: string;
    readonly arguments?: readonly string[];
    readonly errorMessage?: string;
    readonly uuid: string;
  }[];
};

// This probably would be better exported from the components lib
export type Option = {
  readonly label: string;
  readonly value: string;
};

const { None, Some } = Helpers;

type BaseProps = {
  readonly selectedOrgId: string;
  readonly parentFieldKind?: FieldKind;
  readonly predicates: PredicateMap;
  readonly baseFieldKinds: readonly Option[];
  readonly formRef: React.RefObject<FormikProps<FormValues>>;
  readonly onPressCancel: () => void;
};

type ExtendFieldFormComponentProps = BaseProps & {
  readonly type: "extend";
  readonly onFormSubmit: (req: ExtendFieldKindRequest) => Promise<void>;
};

type EditFieldFormComponentProps = BaseProps & {
  readonly type: "edit";
  readonly onFormSubmit: (req: EditFieldKindRequest) => Promise<void>;
};

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const FieldFormComponent = ({
  baseFieldKinds,
  formRef,
  onFormSubmit,
  onPressCancel,
  parentFieldKind,
  predicates,
  selectedOrgId,
  type,
}: EditFieldFormComponentProps | ExtendFieldFormComponentProps) => {
  const { theme } = useTheme();
  const { name: routeName } =
    useRoute<RouteProp<RouteParams, "CreateField" | "EditField" | "ExtendField">>();

  const styles = StyleSheet.create({
    container: {
      alignSelf: "center",
      flex: 1,
      minWidth: 960,
    },
    criteriaForm: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    divider: {
      backgroundColor: theme.colors.border.disabled,
      height: 1,
      marginVertical: theme.spacing.xxl,
    },
    fakeField: {
      backgroundColor: theme.colors.action.disabled,
      borderColor: theme.colors.border.subdued,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 48,
      justifyContent: "center",
      paddingHorizontal: theme.spacing.m,
    },
    footer: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.subdued,
      borderTopWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    headerWithButton: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    narrowInput: {
      maxWidth: 230,
      minWidth: 230,
      paddingRight: 10,
    },
    noRules: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: 44,
      paddingVertical: 30,
    },
    ruleContainer: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: 44,
      paddingVertical: theme.spacing.l,
    },
    ruleItem: {
      backgroundColor: theme.colors.background.white,
      borderTopColor: theme.colors.border.default,
      borderTopWidth: 1,
      paddingHorizontal: 44,
      paddingVertical: theme.spacing.xl,
    },
    wrapper: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
  });

  const handleFormSubmit = () => {
    if (Some(formRef) && Some(formRef.current)) {
      formRef.current.handleSubmit();
    }
  };

  const validationSchema = Yup.object().shape({
    dataType: Yup.string().required("Data type is required"),
    description: Yup.string().optional().trim().max(300, "300 maximum character limit"),
    name: Yup.string()
      .required("Field name is required")
      .trim()
      .max(90, "90 maximum character limit"),
    validationRules: Yup.array().of(
      Yup.object().shape({
        errorMessage: Yup.string().optional().trim().max(120, "Should not exceed 120 character"),
        predicate: Yup.string().required("Please select criteria"),
      })
    ),
  });

  const errorText = (errorMsg: string) => (
    <View style={{ paddingVertical: theme.spacing.s }}>
      {/* TODO: Implement 8 font size*/}
      <Body
        style={{ color: theme.colors.text.alert.critical, fontSize: theme.fontSizes.s.fontSize }}
      >
        {errorMsg}
      </Body>
    </View>
  );

  const getPredicatesForDataType = (dataType: string) => predicates[dataType as keyof PredicateMap];

  const getPredicateOptions = (dataType: string | undefined): readonly Option[] => {
    const dt =
      Some(parentFieldKind) && Some(parentFieldKind.dataType)
        ? parentFieldKind.dataType
        : baseFieldKinds.filter(fk => fk.value === dataType)[0]?.label ?? "";
    const p = getPredicatesForDataType(dt.charAt(0).toLowerCase() + dt.slice(1));

    if (None(p)) {
      return [];
    }

    return Object.keys(p)
      .filter(f => f !== "fieldHasValue")
      .map(k => ({
        label: p[k as keyof typeof p]?.readable ?? "",
        value: k,
      }));
  };

  const getExistingPermission = (): {
    readonly extendPermission: PermissionsValue;
    readonly readPermission: PermissionsValue;
  } => {
    // Used permissions instead of shareablePermissions since it gets incorrect values
    const fieldKindPermissions = parentFieldKind?.permissions;

    if (None(fieldKindPermissions)) {
      return {
        extendPermission: "no",
        readPermission: "no",
      };
    }

    const permissions = fieldKindPermissions
      .map(permission => {
        if (permission.permitted.grantedToName === "All") {
          return permission.action;
        }

        return undefined;
      })
      .filter(_ => Some(_));

    if (permissions.length <= 0) {
      return {
        extendPermission: "no",
        readPermission: "no",
      };
    }

    if (permissions.includes("extend")) {
      return {
        extendPermission: "yes",
        readPermission: "yes",
      };
    }

    return {
      extendPermission: "no",
      readPermission: "yes",
    };
  };

  const submitForm = () => {
    const values = formRef.current?.values;
    if (None(values)) {
      return;
    }
    const readPermission = values.permissions.readPermission === "no" ? "None" : "All";
    const extendPermission =
      values.permissions.readPermission === "yes" && values.permissions.extendPermission === "yes"
        ? "All"
        : "None";
    const description =
      Some(values.description) && values.description.length > 0 ? values.description : "";

    // Copying the implementation from extend
    // share permissions are identical to permissions
    if (type === "edit") {
      onFormSubmit({
        editFieldKindRequest: {
          description,
          name: values.name,
          permissions: [
            {
              action: "extend",
              permissibleToPermit: { action: "extend", grantedToName: extendPermission },
              permitted: { action: "extend", grantedToName: extendPermission },
            },
            {
              action: "read",
              permissibleToPermit: { action: "read", grantedToName: readPermission },
              permitted: { action: "read", grantedToName: readPermission },
            },
          ],
          ruleValid: values.validationRules?.map(({ errorMessage, predicate, ...vr }) => ({
            arguments: [...(vr.arguments ?? [])],
            errorMessage,
            predicate: capitalize(predicate ?? "") as PredicatesEnum,
          })),
        },
        fieldKindID: parentFieldKind?.fieldKindID ?? values.dataType ?? "",
        orgID: selectedOrgId,
      });
    }

    if (type === "extend") {
      onFormSubmit({
        extendFieldKindRequest: {
          description,
          extendPermission,
          name: Some(values.name) ? values.name.trim() : null,
          readPermission,
          ruleValid: values.validationRules?.map(({ errorMessage, predicate, ...vr }) => ({
            arguments: [...(vr.arguments ?? [])],
            errorMessage,
            predicate: capitalize(predicate ?? "") as PredicatesEnum,
          })),
          shareExtendPermission: extendPermission,
          shareReadPermission: readPermission,
        },
        fieldKindID: parentFieldKind?.fieldKindID ?? values.dataType ?? "",
        orgID: selectedOrgId,
      });
    }
  };

  const toCamelCase = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

  const getFieldArguments = (ruleCondition: RuleCondition) => {
    const { arguments: argValues, predicate } = ruleCondition;
    if (argValues.length <= 0) {
      if (predicate === "AfterNow" || predicate === "BeforeNow") {
        return getDateTimeString(new Date());
      }
      if (predicate === "AfterToday" || predicate === "BeforeToday") {
        return getDateString(new Date());
      }

      return "";
    }

    if (
      predicate === "AfterThisDate" ||
      predicate === "BeforeThisDate" ||
      predicate === "EqualToDate"
    ) {
      return getDateString(argValues[0]);
    }

    if (predicate === "AfterThisDatetime" || predicate === "BeforeThisDatetime") {
      return getDateTimeString(argValues[0]);
    }

    if (predicate === "AfterThisTimeOfDay" || predicate === "BeforeThisTimeOfDay") {
      return argValues.join(":");
    }

    return argValues[0];
  };

  const getRulesToDisplay = () => {
    if (None(parentFieldKind)) {
      return [];
    }

    if (routeName === "ExtendField") {
      return [
        ...(parentFieldKind.inheritedValidationRules ?? []),
        parentFieldKind.ownValidationRules,
      ].filter(rules => Some(rules));
    }

    return parentFieldKind.inheritedValidationRules ?? [];
  };

  const getPredicateOptionsWithoutDuplicates = () => {
    const orgCreatedRules = formRef.current?.values.validationRules ?? [];
    const orgCreatedPredicated = orgCreatedRules.map(_ => _.predicate?.toLocaleLowerCase() ?? "");

    const inheritedPredicates = getRulesToDisplay()
      .map(rule => rule.ruleConditions)
      .flat()
      .map(_ => _.predicate.toLocaleLowerCase());

    const existingPredicates = [...inheritedPredicates, ...orgCreatedPredicated];

    const values = formRef.current?.values;

    const options = getPredicateOptions(values?.dataType).filter(
      _ => !existingPredicates.includes(_.value.toLocaleLowerCase())
    );

    return options;
  };

  const getOwnRules = () => {
    if (Some(parentFieldKind) && Some(parentFieldKind.ownValidationRules)) {
      return parentFieldKind.ownValidationRules.ruleConditions.map(rule => ({
        arguments: rule.arguments,
        errorMessage: rule.errorMessage,
        predicate: toCamelCase(rule.predicate),
        uuid: uuidv4(),
      }));
    }

    return [];
  };

  return (
    <View style={styles.wrapper}>
      <ScrollView>
        <View style={styles.container}>
          <Formik
            initialValues={{
              dataType: parentFieldKind?.dataType ?? "",
              description: parentFieldKind?.description ?? "",
              name:
                routeName === "ExtendField"
                  ? `Extension of ${parentFieldKind?.name ?? ""}`
                  : parentFieldKind?.name,
              permissions: Some(parentFieldKind?.shareablePermissions)
                ? getExistingPermission()
                : {
                    extendPermission: "yes",
                    readPermission: "yes",
                  },
              validationRules: routeName === "EditField" ? getOwnRules() : [],
            }}
            innerRef={formRef}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {({ handleBlur, handleChange, setFieldTouched, setFieldValue, values }) => (
              <Form>
                <VerticalSpacer size={theme.spacing.xxl} />
                <Heading
                  bold
                  level="3"
                  testProps={{
                    elementName: "FieldNameHeader",
                    screenName: `${type}FieldForm`,
                  }}
                >
                  Field name and type
                </Heading>
                <VerticalSpacer size={theme.spacing.xxl} />
                <View style={{ maxWidth: 468 }}>
                  <TextInput
                    label="Name *"
                    onBlur={() => {
                      setFieldTouched("name", true);
                      handleBlur("name");
                    }}
                    onChangeText={handleChange("name")}
                    placeholder="Name"
                    size="large"
                    testProps={{
                      elementName: "Name",
                      screenName: `${type}FieldForm`,
                    }}
                    value={values.name}
                  />
                  <ErrorMessage name="name">{errorText}</ErrorMessage>
                </View>

                <VerticalSpacer size={theme.spacing.xxl} />

                <View>
                  <TextInput
                    label="Description"
                    numberOfLines={4}
                    onBlur={() => {
                      setFieldTouched("description", true);
                      handleBlur("description");
                    }}
                    onChangeText={handleChange("description")}
                    placeholder="Description"
                    size="large"
                    testProps={{
                      elementName: "Description",
                      screenName: `${type}FieldForm`,
                    }}
                    value={values.description}
                  />
                  <VerticalSpacer size={theme.spacing.s} />
                  <Body
                    style={{
                      fontSize: theme.fontSizes.s.fontSize,
                      lineHeight: theme.fontSizes.s.lineHeight,
                    }}
                    testProps={{
                      elementName: "MaxCharacterLimit",
                      screenName: `${type}FieldForm`,
                    }}
                  >
                    300 maximum character limit
                  </Body>
                  <ErrorMessage name="description">{errorText}</ErrorMessage>
                </View>

                <VerticalSpacer size={theme.spacing.xxl} />
                <View style={{ maxWidth: 468 }}>
                  <Select
                    defaultValue={
                      Some(parentFieldKind) && Some(parentFieldKind.dataType)
                        ? {
                            label: parentFieldKind.dataType,
                            value: parentFieldKind.fieldKindID,
                          }
                        : baseFieldKinds.find(bfk => bfk.value === values.dataType)
                    }
                    disabled={Some(parentFieldKind)}
                    getOptionValue={option => option.label}
                    label="Data type *"
                    onSelectOption={o => {
                      setFieldValue("dataType", o.value);
                      setFieldValue("validationRules", []);
                    }}
                    options={baseFieldKinds}
                    placeholder={{
                      label: "Data type",
                      value: "",
                    }}
                    size="large"
                    testProps={{
                      elementName: "DataType",
                      screenName: `${type}FieldForm`,
                    }}
                    usePortal
                  />
                  <ErrorMessage name="dataType">{errorText}</ErrorMessage>
                </View>

                <View style={styles.divider} />

                <SelectPermissions
                  onSelectOption={(permission, value) => {
                    setFieldTouched(`permissions.${permission}`, true);
                    setFieldValue(`permissions.${permission}`, value);
                  }}
                  permissionFor="field"
                  testProps={{
                    elementName: "SharePermissions",
                    screenName: `${type}FieldForm`,
                  }}
                  values={{
                    extendPermission: values.permissions.extendPermission,
                    readPermission: values.permissions.readPermission,
                  }}
                />

                <View style={styles.divider} />

                <FieldArray name="validationRules">
                  {({ push, remove }) => (
                    <>
                      <View style={styles.headerWithButton}>
                        <Heading
                          bold
                          level="3"
                          testProps={{
                            elementName: "FieldValidationRulesHeader",
                            screenName: `${type}FieldForm`,
                          }}
                        >
                          Field validation rules
                        </Heading>
                        <Button
                          disabled={
                            None(values.dataType) ||
                            values.dataType === "" ||
                            (values.validationRules?.length ?? 0) >=
                              getPredicateOptions(values.dataType).length
                          }
                          iconLeft="addSmallDefault"
                          onPress={() => {
                            push({
                              predicate: undefined,
                              uuid: uuidv4(),
                            });
                          }}
                          size="small"
                          testProps={{
                            elementName: "AddRuleButton",
                            screenName: `${type}FieldForm`,
                          }}
                          text="Add Rule"
                          type="secondary"
                        />
                      </View>
                      {Some(parentFieldKind) && (
                        <>
                          <VerticalSpacer size={theme.spacing.xxl} />
                          <Heading level="3">Inherited rules</Heading>
                          <VerticalSpacer size={theme.spacing.xxl} />
                          <View style={styles.ruleContainer}>
                            <Heading
                              level="5"
                              testProps={{
                                elementName: "RuleContainerHeader",
                                screenName: `${type}FieldForm`,
                              }}
                            >
                              Criteria
                            </Heading>
                          </View>
                          {getRulesToDisplay().length > 0 ? (
                            <>
                              {getRulesToDisplay().map(record => (
                                <View key={uuidv4()}>
                                  {record.ruleConditions.map(vr => (
                                    <View key={uuidv4()} style={styles.ruleItem}>
                                      <View style={styles.headerWithButton}>
                                        <View style={styles.criteriaForm}>
                                          <View style={styles.narrowInput}>
                                            <Select
                                              defaultValue={getPredicateOptions(
                                                parentFieldKind.dataType
                                              ).find(_ => _.value === toCamelCase(vr.predicate))}
                                              disabled
                                              label=""
                                              options={getPredicateOptions(
                                                parentFieldKind.dataType
                                              )}
                                              placeholder={{ label: "Select Rule", value: "" }}
                                              showLabel={false}
                                              testProps={{
                                                elementName: "Rule",
                                                screenName: `${type}FieldForm`,
                                              }}
                                              usePortal
                                            />
                                          </View>
                                          {Some(vr.arguments) && (
                                            <View
                                              style={[
                                                styles.fakeField,
                                                { height: 40, minWidth: 162 },
                                              ]}
                                            >
                                              <Body>{getFieldArguments(vr)}</Body>
                                            </View>
                                          )}
                                        </View>
                                        <Tooltip text="Inherited fields cannot be edited." />
                                      </View>
                                      {Some(vr.predicate) && (
                                        <>
                                          <VerticalSpacer size={theme.spacing.xl} />
                                          <Body>Error message</Body>
                                          <VerticalSpacer size={theme.spacing.xs} />
                                          <View style={[styles.fakeField, { width: 824 }]}>
                                            <Body>{vr.errorMessage}</Body>
                                          </View>
                                          <VerticalSpacer size={theme.spacing.s} />
                                          <Body
                                            style={{
                                              fontSize: theme.fontSizes.s.fontSize,
                                              lineHeight: theme.fontSizes.s.lineHeight,
                                            }}
                                          >
                                            120 maximum character limit
                                          </Body>
                                        </>
                                      )}
                                    </View>
                                  ))}
                                </View>
                              ))}
                            </>
                          ) : (
                            <>
                              <View
                                style={{ backgroundColor: theme.colors.border.default, height: 1 }}
                              />
                              <View style={styles.noRules}>
                                <Body>There are no inherited rules</Body>
                              </View>
                            </>
                          )}
                        </>
                      )}

                      {Some(parentFieldKind) && (
                        <>
                          <VerticalSpacer size={theme.spacing.xxl} />
                          <Heading bold level="3">
                            Org created rules
                          </Heading>
                        </>
                      )}
                      <VerticalSpacer
                        size={Some(parentFieldKind) ? theme.spacing.xxl : theme.spacing.l}
                      />
                      <>
                        <View style={styles.ruleContainer}>
                          <Heading
                            level="5"
                            testProps={{
                              elementName: "RuleContainerHeader",
                              screenName: `${type}FieldForm`,
                            }}
                          >
                            Criteria
                          </Heading>
                        </View>
                        {Some(values.validationRules) && values.validationRules.length > 0 ? (
                          <>
                            {values.validationRules.map((vr, index) => (
                              <View key={vr.uuid} style={styles.ruleItem}>
                                <View style={styles.headerWithButton}>
                                  <View style={styles.criteriaForm}>
                                    <View style={styles.narrowInput}>
                                      <Select
                                        defaultValue={getPredicateOptions(values.dataType).find(
                                          _ => _.value === vr.predicate
                                        )}
                                        getOptionValue={option => option.label}
                                        key={vr.predicate}
                                        label=""
                                        onSelectOption={o => {
                                          setFieldValue(
                                            `validationRules[${index}].predicate`,
                                            o.value
                                          );
                                        }}
                                        options={getPredicateOptionsWithoutDuplicates()}
                                        placeholder={{ label: "Select Rule", value: "" }}
                                        showLabel={false}
                                        testProps={{
                                          elementName: `NewRule${index}`,
                                          screenName: `${type}FieldForm`,
                                        }}
                                        usePortal
                                      />
                                      <ErrorMessage name={`validationRules[${index}].predicate`}>
                                        {errorText}
                                      </ErrorMessage>
                                    </View>
                                    {Some(vr.predicate) && (
                                      <RulesArgumentInput
                                        handleChange={a => {
                                          setFieldValue(`validationRules[${index}].arguments`, a);
                                        }}
                                        predicate={vr.predicate as Predicate}
                                        testProps={{
                                          elementName: `NewRule${index}ArgumentInput`,
                                          screenName: `${type}FieldForm`,
                                        }}
                                        value={vr.arguments}
                                      />
                                    )}
                                  </View>
                                  <Pressable onPress={() => remove(index)}>
                                    <Icon
                                      name="closeSmallDefault"
                                      testProps={{
                                        elementName: `NewRule${index}CloseButton`,
                                        screenName: `${type}FieldForm`,
                                      }}
                                    />
                                  </Pressable>
                                </View>
                                {Some(vr.predicate) && (
                                  <>
                                    <VerticalSpacer size={theme.spacing.xl} />
                                    <View style={{ width: 824 }}>
                                      <TextInput
                                        label="Error message"
                                        onChangeText={handleChange(
                                          `validationRules[${index}].errorMessage`
                                        )}
                                        placeholder="Message"
                                        testProps={{
                                          elementName: `NewRule${index}ErrorMessage`,
                                          screenName: `${type}FieldForm`,
                                        }}
                                        value={vr.errorMessage}
                                      />
                                    </View>
                                    <VerticalSpacer size={theme.spacing.s} />
                                    <Body
                                      style={{
                                        fontSize: theme.fontSizes.s.fontSize,
                                        lineHeight: theme.fontSizes.s.lineHeight,
                                      }}
                                      testProps={{
                                        elementName: `NewRule${index}InputLimit`,
                                        screenName: `${type}FieldForm`,
                                      }}
                                    >
                                      120 maximum character limit
                                    </Body>
                                    <ErrorMessage name={`validationRules[${index}].errorMessage`}>
                                      {errorText}
                                    </ErrorMessage>
                                  </>
                                )}
                              </View>
                            ))}
                          </>
                        ) : (
                          <>
                            <View
                              style={{ backgroundColor: theme.colors.border.default, height: 1 }}
                            />
                            <View style={styles.noRules}>
                              <Body
                                testProps={{
                                  elementName: `NoRulesPlaceholder`,
                                  screenName: `${type}FieldForm`,
                                }}
                              >
                                {Some(parentFieldKind)
                                  ? "There are no org created rules"
                                  : "There are no rules"}
                              </Body>
                            </View>
                            <View style={{ height: 100 }} />
                          </>
                        )}
                        <View style={{ height: 80 }} />
                      </>
                    </>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        {routeName === "CreateField" ? (
          <Button
            onPress={handleFormSubmit}
            size="medium"
            testProps={{
              elementName: "CreateFieldButton",
              screenName: `${type}FieldForm`,
            }}
            text="Create"
          />
        ) : (
          <>
            <Button
              onPress={onPressCancel}
              size="medium"
              testProps={{
                elementName: "CancelCreateFieldButton",
                screenName: `${type}FieldForm`,
              }}
              text="Cancel"
              type="secondary"
            />
            <HorizontalSpacer />
            <Button
              onPress={handleFormSubmit}
              size="medium"
              testProps={{
                elementName: "SaveFieldButton",
                screenName: `${type}FieldForm`,
              }}
              text="Save"
            />
          </>
        )}
      </View>
    </View>
  );
};
