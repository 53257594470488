// Copyright 2023 Merit International Inc. All Rights Reserved

import * as Yup from "yup";
import { Body, Button, TextInput, useTheme } from "@merit/frontend-components";
import { Formik } from "formik";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Spin } from "../../components";
import { StyleSheet, View } from "react-native";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import type { FieldMetaProps } from "formik";
import type { OPTestProps } from "../../types/TestProps";

const { Some } = Helpers;

export type FormValues = {
  readonly adminEmail: string;
  readonly adminFirstName: string;
  readonly adminLastName: string;
  readonly adminPhone: string;
};

type Props = {
  readonly onClose: () => void;
  readonly testProps: OPTestProps;
};

const defaultFormValues: FormValues = {
  adminEmail: "",
  adminFirstName: "",
  adminLastName: "",
  adminPhone: "",
};

const phoneNumberRegEx = new RegExp("^\\d{10}$", "u");

const isValid = (getFieldMeta: (name: string) => FieldMetaProps<string>, field: string) =>
  Some(getFieldMeta(field).error) && getFieldMeta(field).touched;

const validationSchema = Yup.object().shape({
  adminEmail: Yup.string().trim().email("Please enter a valid email").required("Email is required"),
  adminFirstName: Yup.string().trim().min(1).max(80).required("First name is required"),
  adminLastName: Yup.string().trim().min(1).max(80).required("Last name is required"),
  adminPhone: Yup.string()
    .trim()
    .required("Phone number is required")
    .test("validate-phoneNumber", "Please enter a valid phone number", number => {
      if (Some(number) && phoneNumberRegEx.test(number)) {
        return true;
      }

      return false;
    }),
});

export const AdminForm = ({ onClose, testProps }: Props) => {
  const { theme } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    horizontalFields: {
      alignSelf: "flex-end",
      width: 74,
    },
  });

  const submitForm = async (values: FormValues) => {
    try {
      setIsLoading(true);
      await api.createAdmin({
        createAdminRequest: values,
        orgID: selectedOrgId,
      });
      setAlert({
        closable: true,
        customContent: (
          <Body style={{ maxWidth: "90%" }}>
            {values.adminFirstName} {values.adminLastName} was successfully added as admin
          </Body>
        ),
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        testProps: {
          elementName: "addAdminSuccess",
          screenName: testProps.screenName,
        },
        type: "success",
      });
    } catch (error) {
      errorHandler(error, {
        elementName: "addAdminError",
        screenName: testProps.screenName,
      });
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Formik
        initialValues={defaultFormValues}
        onSubmit={(values: FormValues) => {
          submitForm(values);
        }}
        validationSchema={validationSchema}
      >
        {({ errors, getFieldMeta, handleBlur, handleChange, handleSubmit, values }) => (
          <>
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <View>
                <TextInput
                  label="First name"
                  onBlur={handleBlur("adminFirstName")}
                  onChangeText={handleChange("adminFirstName")}
                  placeholder="First name"
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}AdminFirstNameTextInput`,
                  }}
                  value={values.adminFirstName}
                />
                {isValid(getFieldMeta, "adminFirstName") && (
                  <Body
                    color={theme.colors.text.alert.critical}
                    style={{ maxWidth: 200 }}
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}AdminFirstNameErrorMessage`,
                    }}
                  >
                    {errors.adminFirstName}
                  </Body>
                )}
              </View>
              <HorizontalSpacer size={theme.spacing.m} />
              <View>
                <TextInput
                  label="Last name"
                  onBlur={handleBlur("adminLastName")}
                  onChangeText={handleChange("adminLastName")}
                  placeholder="Last name"
                  testProps={{
                    ...testProps,
                    elementName: `${testProps.elementName}AdminLastNameTextInput`,
                  }}
                  value={values.adminLastName}
                />
                {isValid(getFieldMeta, "adminLastName") && (
                  <Body
                    color={theme.colors.text.alert.critical}
                    style={{ maxWidth: 200 }}
                    testProps={{
                      ...testProps,
                      elementName: `${testProps.elementName}AdminLastNameErrorMessage`,
                    }}
                  >
                    {errors.adminLastName}
                  </Body>
                )}
              </View>
            </View>
            <VerticalSpacer size={theme.spacing.s} />
            <TextInput
              label="Email"
              onBlur={handleBlur("adminEmail")}
              onChangeText={handleChange("adminEmail")}
              placeholder="Email"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}AdminEmailTextInput`,
              }}
              value={values.adminEmail}
            />
            {isValid(getFieldMeta, "adminEmail") && (
              <Body
                color={theme.colors.text.alert.critical}
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AdminEmailErrorMessage`,
                }}
              >
                {errors.adminEmail}
              </Body>
            )}
            <VerticalSpacer size={theme.spacing.s} />
            <TextInput
              label="Phone number"
              onBlur={handleBlur("adminPhone")}
              onChangeText={handleChange("adminPhone")}
              placeholder="Phone number"
              testProps={{
                ...testProps,
                elementName: `${testProps.elementName}AdminPhoneTextInput`,
              }}
              value={values.adminPhone}
            />
            {isValid(getFieldMeta, "adminPhone") && (
              <Body
                color={theme.colors.text.alert.critical}
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AdminPhoneErrorMessage`,
                }}
              >
                {errors.adminPhone}
              </Body>
            )}
            <VerticalSpacer size={theme.spacing.m} />
            <View style={styles.horizontalFields}>
              <Button
                onPress={handleSubmit}
                size="medium"
                testProps={{
                  ...testProps,
                  elementName: `${testProps.elementName}AddButton`,
                }}
                text="Add"
                type="primary"
              />
            </View>
          </>
        )}
      </Formik>
    </Spin>
  );
};
