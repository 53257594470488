import { Body, Button, Heading } from "@merit/frontend-components";
import { Datagrid, DatagridBody, Spin } from "@src/components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "@src/screens/Templates/Templates";
import { ScrollView, View } from "react-native";
import { getDateTimeString } from "@src/utils/time";
import { useApi } from "@src/api/api";
import { useDataRefreshEffect } from "@src/hooks";
import { useDatasourcesSearchData } from "@src/screens/Datasources/useDatasourcesSearchData";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import React, { useCallback, useEffect } from "react";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { Search200ResponseDataSourcesInner } from "@src/gen/org-portal";

type Props = {
  readonly objectName?: string | undefined;
  readonly setDatasourceId: (id: string) => void;
  readonly createNewDatasource: () => void;
  readonly setResultsCount: (count: number | undefined) => void;
  readonly refresh: boolean;
  readonly setRefresh: (value: boolean) => void;
};

export const DatasourcesDatagridSearch = ({
  objectName,
  refresh,
  setDatasourceId,
  setRefresh,
  setResultsCount,
}: Props) => {
  const { Some } = Helpers;
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();

  const datasources = useDatasourcesSearchData(api, selectedOrgId, objectName);

  useEffect(() => {
    if (datasources.data.length > 0) {
      setResultsCount(datasources.data.length);
    } else {
      setResultsCount(undefined);
    }
  }, [datasources, setResultsCount]);

  const columns: readonly DatagridColumn<Search200ResponseDataSourcesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    { key: "id", label: "Data source ID", size: 360 },
    {
      key: "lastIngestionTime",
      label: "Last ingestion",
      renderer: (item, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(item.lastIngestion) && item.lastIngestion !== ""
            ? getDateTimeString(item.lastIngestion)
            : "--"}
        </Body>
      ),
      size: 200,
    },
    {
      key: "action",
      label: "Actions",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      renderer: (datasource, testProps) => (
        <View style={{ maxWidth: 80 }}>
          <Button
            onPress={() => {
              setDatasourceId(datasource.id);
            }}
            size="small"
            testProps={{
              elementId: datasource.id,
              elementName: Some(testProps) ? `${testProps.elementName}ViewButton` : "",
              screenName: SCREEN_NAME,
            }}
            text="View"
            type="secondary"
          />
        </View>
      ),
      size: 120,
    },
  ];

  useEffect(() => {
    if (refresh) {
      datasources.refresh();
      setRefresh(false);
    }
  }, [datasources, refresh, setRefresh]);

  useDataRefreshEffect(
    useCallback(() => {
      datasources.refresh();
    }, [datasources])
  );

  return (
    <>
      <>
        <View style={{ flex: 1 }}>
          <ScrollView>
            {datasources.loading ? (
              <Spin />
            ) : (
              <>
                <View style={{ paddingLeft: 32 }}>
                  <Heading level="4">{`Results (${datasources.data.length})`}</Heading>
                </View>
                <Datagrid
                  columns={columns}
                  testProps={{ elementName: "datasourcesSearchView", screenName: SCREEN_NAME }}
                >
                  <DatagridBody
                    columns={columns}
                    data={datasources.data}
                    testProps={{
                      elementId: "id",
                      elementName: "datasourcesSearchView",
                      screenName: SCREEN_NAME,
                    }}
                    testPropsElementIdKey="id"
                  />
                </Datagrid>
              </>
            )}
          </ScrollView>
        </View>
      </>
    </>
  );
};
