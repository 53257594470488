// Copyright 2024 Merit International Inc. All Rights Reserved

import { Heading, useTheme } from "@merit/frontend-components";
import { MenuLink } from "./MenuLink";
import { VERSION } from "../../constants/version";
import { VerticalSpacer } from "../Spacer";
import { View } from "react-native";
import { useLogout } from "@src/utils/useLogout";
import React from "react";

export const ProfileMenu = () => {
  const { theme } = useTheme();
  const { logout } = useLogout();

  return (
    <View
      style={{
        alignItems: "flex-end",
        backgroundColor: theme.colors.background.white,
        borderRadius: 4,
        paddingHorizontal: 24,
        paddingVertical: 16,
        width: 160,
      }}
    >
      <MenuLink
        onPress={() => {
          window.open("https://member.merits.com/login", "_blank");
        }}
        size="oneline"
        subtext=""
        text="My profile"
      />

      <View style={{ backgroundColor: theme.colors.border.subdued, height: 1, width: "100%" }} />

      <MenuLink
        onPress={() => {
          logout();
        }}
        size="oneline"
        subtext=""
        text="Log out"
      />

      <VerticalSpacer size={16} />
      <Heading color={theme.colors.text.subdued} level="6">
        {VERSION}
      </Heading>
    </View>
  );
};
