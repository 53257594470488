/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Errors,
  PolicyRequest,
  PolicyRequestPatch,
} from '../models/index';
import {
    ErrorsFromJSON,
    ErrorsToJSON,
    PolicyRequestFromJSON,
    PolicyRequestToJSON,
    PolicyRequestPatchFromJSON,
    PolicyRequestPatchToJSON,
} from '../models/index';

export interface CreatePolicyRequestRequest {
    policyID: string;
    targetID: string;
    authorization?: string;
}

export interface GetPolicyRequestRequest {
    policyID: string;
    targetID: string;
    authorization?: string;
}

export interface PatchPolicyRequestRequest {
    policyID: string;
    targetID: string;
    authorization?: string;
    properties?: PolicyRequestPatch;
}

/**
 * 
 */
export class PolicyRequestsApi extends runtime.BaseAPI {

    /**
     * `create:policy_request`  Request to run a policy on a target 
     * Create Policy Request
     */
    async createPolicyRequestRaw(requestParameters: CreatePolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolicyRequest>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling createPolicyRequest.');
        }

        if (requestParameters.targetID === null || requestParameters.targetID === undefined) {
            throw new runtime.RequiredError('targetID','Required parameter requestParameters.targetID was null or undefined when calling createPolicyRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies/{policyID}/policy-requests/{targetID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))).replace(`{${"targetID"}}`, encodeURIComponent(String(requestParameters.targetID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyRequestFromJSON(jsonValue));
    }

    /**
     * `create:policy_request`  Request to run a policy on a target 
     * Create Policy Request
     */
    async createPolicyRequest(requestParameters: CreatePolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolicyRequest> {
        const response = await this.createPolicyRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `read:policy_request`  Get the result of a policy run on a target 
     * Get Policy Request
     */
    async getPolicyRequestRaw(requestParameters: GetPolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolicyRequest>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getPolicyRequest.');
        }

        if (requestParameters.targetID === null || requestParameters.targetID === undefined) {
            throw new runtime.RequiredError('targetID','Required parameter requestParameters.targetID was null or undefined when calling getPolicyRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies/{policyID}/policy-requests/{targetID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))).replace(`{${"targetID"}}`, encodeURIComponent(String(requestParameters.targetID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyRequestFromJSON(jsonValue));
    }

    /**
     * `read:policy_request`  Get the result of a policy run on a target 
     * Get Policy Request
     */
    async getPolicyRequest(requestParameters: GetPolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolicyRequest> {
        const response = await this.getPolicyRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * `update:policy_request`  Approve or deny a policy request 
     * Approve/Deny Policy Request
     */
    async patchPolicyRequestRaw(requestParameters: PatchPolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PolicyRequest>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling patchPolicyRequest.');
        }

        if (requestParameters.targetID === null || requestParameters.targetID === undefined) {
            throw new runtime.RequiredError('targetID','Required parameter requestParameters.targetID was null or undefined when calling patchPolicyRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/v1/policies/{policyID}/policy-requests/{targetID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))).replace(`{${"targetID"}}`, encodeURIComponent(String(requestParameters.targetID))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PolicyRequestPatchToJSON(requestParameters.properties),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolicyRequestFromJSON(jsonValue));
    }

    /**
     * `update:policy_request`  Approve or deny a policy request 
     * Approve/Deny Policy Request
     */
    async patchPolicyRequest(requestParameters: PatchPolicyRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PolicyRequest> {
        const response = await this.patchPolicyRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
