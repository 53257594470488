// Copyright 2024 Merit International Inc. All Rights Reserved

import { HorizontalSpacer } from "../../../components/Spacer";
import { SCREEN_NAME } from "./ConnectedOrganizations";
import { Select, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";

const OPTIONS = [
  { label: "Issued", value: "issued" },
  { label: "Received", value: "received" },
];

type Props = {
  readonly onSelect: (value: string) => void;
  readonly placeholder: string;
  readonly selectedFilterValue?: string;
};

export const InterActionFilter = ({ onSelect, placeholder, selectedFilterValue }: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.surface.subdued,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: theme.spacing.l,
    },
    fieldItem: {
      minWidth: 325,
    },
  });

  return (
    <View style={styles.container}>
      <>
        <View style={styles.fieldItem}>
          <Select
            defaultValue={OPTIONS.find(_ => _.value === selectedFilterValue)}
            label="Interaction"
            onSelectOption={option => {
              if (typeof option.value === "string") {
                onSelect(option.value);
              }
            }}
            options={OPTIONS}
            placeholder={{
              label: placeholder,
              value: "",
            }}
            testProps={{
              elementName: "filterModalViewInterActionType",
              screenName: SCREEN_NAME,
            }}
            usePortal
          />
        </View>
        <HorizontalSpacer />
      </>
    </View>
  );
};
