// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../../components/Spacer";
import { SCREEN_NAME } from "./LinkedAppDetails";
import { View } from "react-native";
import { getDateTimeString } from "@src/utils/time";
import { uniq } from "lodash";
import { useLoadedConfigurationState } from "../../../hooks/useLoadedConfigurationState";
import React, { useMemo } from "react";
import type { GetAllAgentsVersions200ResponseInner } from "../../../gen/org-portal";

const { None, Some } = Helpers;

type Props = {
  readonly appDetails: GetAllAgentsVersions200ResponseInner;
};

type Capabilities = {
  readonly agents: readonly string[];
  readonly container: readonly string[];
  readonly datasource: readonly string[];
  readonly entity: readonly string[];
  readonly fields: readonly string[];
  readonly ingestion: readonly string[];
  readonly integration: readonly string[];
  readonly persona: readonly string[];
  readonly policy: readonly string[];
  readonly templates: readonly string[];
  readonly record: readonly string[];
};

export const Overview = ({ appDetails: { id, orgName, owner: orgID, versions } }: Props) => {
  const { theme } = useTheme();
  const { configuration } = useLoadedConfigurationState();

  const latestVersion = versions[0];
  const description = latestVersion.description;

  const getOrgName = () => {
    if (None(orgName)) {
      return "--";
    }
    if (configuration.solUUID === orgID) {
      return configuration.solName;
    }

    return orgName;
  };

  const capabilitiesByCategory = useMemo(() => {
    const initialValues = {
      agents: [],
      container: [],
      datasource: [],
      entity: [],
      fields: [],
      ingestion: [],
      integration: [],
      persona: [],
      policy: [],
      record: [],
      templates: [],
    };

    if (None(latestVersion.capabilities) || latestVersion.capabilities.length === 0) {
      return undefined;
    }

    return latestVersion.capabilities.reduce<Capabilities>((prev, current) => {
      if (current.key.includes("agent")) {
        return {
          ...prev,
          agents: [...prev.agents, current.name],
        };
      }

      if (current.key.includes("container")) {
        return {
          ...prev,
          container: [...prev.container, current.name],
        };
      }

      if (current.key.includes("datasource")) {
        return {
          ...prev,
          datasource: [...prev.datasource, current.name],
        };
      }

      if (current.key.includes("entity")) {
        return {
          ...prev,
          entity: [...prev.entity, current.name],
        };
      }

      if (current.key.includes("kind")) {
        return {
          ...prev,
          fields: [...prev.fields, current.name],
        };
      }

      if (current.key.includes("ingestion")) {
        return {
          ...prev,
          ingestion: [...prev.ingestion, current.name],
        };
      }

      if (current.key.includes("integration")) {
        return {
          ...prev,
          integration: [...prev.integration, current.name],
        };
      }

      if (current.key.includes("persona")) {
        return {
          ...prev,
          persona: [...prev.persona, current.name],
        };
      }

      if (current.key.includes("policy")) {
        return {
          ...prev,
          policy: [...prev.policy, current.name],
        };
      }

      if (current.key.includes("template")) {
        return {
          ...prev,
          templates: [...prev.templates, current.name],
        };
      }

      if (current.key.includes("record")) {
        return {
          ...prev,
          record: [...prev.record, current.name],
        };
      }

      return prev;
    }, initialValues);
  }, [latestVersion.capabilities]);

  return (
    <View style={{ marginHorizontal: 32 }}>
      <VerticalSpacer size={theme.spacing.xxl} />
      <>
        <Heading level="5">Description</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabDescription",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(description) && description === "" ? "--" : description}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Owner</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabViewOwnerName",
              screenName: SCREEN_NAME,
            }}
          >
            {getOrgName()}
          </Body>
          <HorizontalSpacer size={10} />
          <Body color={theme.colors.border.subdued}>|</Body>
          <HorizontalSpacer size={10} />
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabOwnerID",
              screenName: SCREEN_NAME,
            }}
          >
            {orgID}
          </Body>
        </View>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Version</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabVersion",
            screenName: SCREEN_NAME,
          }}
        >
          {latestVersion.version}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Version ID</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabVersionID",
            screenName: SCREEN_NAME,
          }}
        >
          {latestVersion.id}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Capabilities</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        {None(capabilitiesByCategory) ? (
          <Body
            testProps={{
              elementId: id,
              elementName: "detailsViewOverviewTabVersionID",
              screenName: SCREEN_NAME,
            }}
          >
            --
          </Body>
        ) : (
          <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {Object.keys(capabilitiesByCategory).map((category, index) => {
              const capabilities = uniq(
                capabilitiesByCategory[category as keyof Capabilities].map(_ => _.toLowerCase())
              );

              return (
                <>
                  <View key={category} style={{ width: "50%" }}>
                    {index > 1 && <VerticalSpacer size={theme.spacing.xxl} />}
                    <Heading capitalize level="5">
                      {category}
                    </Heading>
                    {capabilities.length > 0 ? (
                      capabilities.map(capability => (
                        <View key={capability}>
                          <VerticalSpacer size={theme.spacing.xs} />
                          <Body capitalize>{capability}</Body>
                        </View>
                      ))
                    ) : (
                      <Body>--</Body>
                    )}
                  </View>
                </>
              );
            })}
          </View>
        )}
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Created at</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabCreatedAt",
            screenName: SCREEN_NAME,
          }}
        >
          {getDateTimeString(latestVersion.createdAt ?? "")}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Approved at</Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabApprovedAt",
            screenName: SCREEN_NAME,
          }}
        >
          {latestVersion.approved === true
            ? getDateTimeString(latestVersion.updatedAt ?? "")
            : "--"}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Data tos </Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabDataTos",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(latestVersion.dataTos) && latestVersion.dataTos.length > 0
            ? latestVersion.dataTos
            : "--"}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Privacy tos </Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabPrivacyTos",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(latestVersion.privacyTos) && latestVersion.privacyTos.length > 0
            ? latestVersion.privacyTos
            : "--"}
        </Body>
      </>

      <VerticalSpacer size={theme.spacing.xxl} />

      <>
        <Heading level="5">Usage tos </Heading>
        <VerticalSpacer size={theme.spacing.xs} />
        <Body
          testProps={{
            elementId: id,
            elementName: "detailsViewOverviewTabUsageTos",
            screenName: SCREEN_NAME,
          }}
        >
          {Some(latestVersion.usageTos) && latestVersion.usageTos.length > 0
            ? latestVersion.usageTos
            : "--"}
        </Body>
      </>
      <VerticalSpacer size={theme.spacing.xxl} />
    </View>
  );
};
