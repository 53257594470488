/* tslint:disable */
/* eslint-disable */
/**
 * org-portal
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAdminRequest
 */
export interface CreateAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminRequest
     */
    adminFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminRequest
     */
    adminLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminRequest
     */
    adminEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminRequest
     */
    adminPhone: string;
}

/**
 * Check if a given object implements the CreateAdminRequest interface.
 */
export function instanceOfCreateAdminRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "adminFirstName" in value;
    isInstance = isInstance && "adminLastName" in value;
    isInstance = isInstance && "adminEmail" in value;
    isInstance = isInstance && "adminPhone" in value;

    return isInstance;
}

export function CreateAdminRequestFromJSON(json: any): CreateAdminRequest {
    return CreateAdminRequestFromJSONTyped(json, false);
}

export function CreateAdminRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAdminRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminFirstName': json['adminFirstName'],
        'adminLastName': json['adminLastName'],
        'adminEmail': json['adminEmail'],
        'adminPhone': json['adminPhone'],
    };
}

export function CreateAdminRequestToJSON(value?: CreateAdminRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminFirstName': value.adminFirstName,
        'adminLastName': value.adminLastName,
        'adminEmail': value.adminEmail,
        'adminPhone': value.adminPhone,
    };
}

