// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "@src/components";
import { SCREEN_NAME } from "./LinkedAppDetails";
import { VerticalSpacer } from "../../../components/Spacer";
import { View } from "react-native";
import { getDateTimeString } from "@src/utils/time";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { GetAllAgentsVersions200ResponseInnerVersionsInner as Version } from "../../../gen/org-portal";

type Props = {
  readonly versions: readonly Version[];
};

export const Versions = ({ versions }: Props) => {
  const { theme } = useTheme();

  const columns: readonly DatagridColumn<Version>[] = [
    {
      key: "version",
      label: "Version",
      size: "flex",
    },
    {
      key: "approvedAt",
      label: "Approved at",
      renderer: ({ approved, updatedAt }, testProps) => (
        <Body testProps={testProps}>
          {approved === true ? getDateTimeString(updatedAt?.toString() ?? "") : "--"}
        </Body>
      ),
      size: "flex",
    },
  ];

  return (
    <View style={{ marginHorizontal: 32 }}>
      <VerticalSpacer size={theme.spacing.xxl} />
      <Datagrid
        columns={columns}
        testProps={{
          elementName: "appVersionsListView",
          screenName: SCREEN_NAME,
        }}
      >
        <DatagridBody
          columns={columns}
          data={versions}
          testProps={{
            elementName: "appVersionsListView",
            screenName: SCREEN_NAME,
          }}
          testPropsElementIdKey="id"
        />
      </Datagrid>
      <VerticalSpacer size={theme.spacing.xxl} />
    </View>
  );
};
