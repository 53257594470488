// Copyright 2024 Merit International Inc. All Rights Reserved

import { Heading, useTheme } from "@merit/frontend-components";
import { View } from "react-native";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";

export const ProfileIcon = () => {
  const { theme } = useTheme();
  const { profile } = useLoggedInAuthState();

  return (
    <View style={{ borderColor: "#C1C7D0", borderRadius: 17, borderWidth: 1 }}>
      <View
        style={{
          alignItems: "center",
          backgroundColor: theme.colors.brand.oceanBlue,
          borderColor: "white",
          borderRadius: 16,
          borderWidth: 1,
          height: 32,
          justifyContent: "center",
          width: 32,
        }}
      >
        <Heading bold color={theme.colors.background.white} level="4">
          {profile.name[0].toUpperCase()}
        </Heading>
      </View>
    </View>
  );
};
